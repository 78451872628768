import React from "react"

import { graphql } from "gatsby"
import { StructuredText } from "react-datocms"
import Layout from "../components/layout"

const PrivacyPage = ({ pageContext, data }) => {
  return (
    <Layout pageData={pageContext}>
      <div className="mx-auto flex max-w-screen-xl flex-col gap-4 p-4">
        <h1 className="text-4xl font-bold text-primary-600">
          {data.datoCmsPrivacy.title}
        </h1>

        <StructuredText data={data.datoCmsPrivacy.policy} />
      </div>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query PrivacyPageQuery($locale: String!) {
    datoCmsPrivacy(locale: { eq: $locale }) {
      locale
      slug
      title
      id: originalId
      policy {
        value
      }
    }
  }
`
